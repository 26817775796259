.overlay{
        background-color: rgba(0, 0, 0, 0.75);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;

}
.carousel{
    height: 475px !important;
}